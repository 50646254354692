import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Em, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				ARTPOTTERY
			</title>
			<meta name={"description"} content={"ArtPottery надає можливість поринути у світ керамічного мистецтва через наші захоплюючі та інтерактивні майстер-класи."} />
			<meta property={"og:title"} content={"ARTPOTTERY"} />
			<meta property={"og:description"} content={"ArtPottery надає можливість поринути у світ керамічного мистецтва через наші захоплюючі та інтерактивні майстер-класи."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/8540798.png?v=2024-09-20T07:30:04.717Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section
			padding="140px 0 140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(120deg,rgba(0,0,0,.2) 0%,rgba(16, 1, 1, 0.73) 100%),--color-dark url(https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Untitled-design-2-1.png?v=2024-09-20T07:30:04.740Z) center/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="20px"
					text-transform="uppercase"
					font="--headline3"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Ласкаво просимо до керамічної майстерні ArtPottery
				</Text>
			</Box>
			<Box
				display="flex"
				margin="-16px -16px -16px -16px"
				flex-wrap="wrap"
				width="100%"
				justify-content="space-between"
			>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px" font="--lead" text-transform="uppercase">
							У ArtPottery ми прагнемо допомогти кожному відкрити для себе радість створення власних керамічних шедеврів.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Наші майстер-класи підходять як для новачків, так і для тих, хто хоче вдосконалити свої навички в гончарному мистецтві.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			/>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Text
					font="--headline2"
					lg-text-align="center"
					margin="16px 0px 24px 0px"
					color="--grey"
					md-text-align="left"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Про нашу майстерню
					</Em>
				</Text>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://images.unsplash.com/photo-1590422749897-47036da0b0ff?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://images.unsplash.com/photo-1590422749897-47036da0b0ff?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1590422749897-47036da0b0ff?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1590422749897-47036da0b0ff?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1590422749897-47036da0b0ff?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1590422749897-47036da0b0ff?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1590422749897-47036da0b0ff?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1590422749897-47036da0b0ff?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					ArtPottery надає можливість поринути у світ керамічного мистецтва через наші захоплюючі та інтерактивні майстер-класи.
				</Text>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_2_1024x1024.webp?v=2024-09-20T07:30:04.723Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_2_1024x1024.webp?v=2024-09-20T07%3A30%3A04.723Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_2_1024x1024.webp?v=2024-09-20T07%3A30%3A04.723Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_2_1024x1024.webp?v=2024-09-20T07%3A30%3A04.723Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_2_1024x1024.webp?v=2024-09-20T07%3A30%3A04.723Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_2_1024x1024.webp?v=2024-09-20T07%3A30%3A04.723Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_2_1024x1024.webp?v=2024-09-20T07%3A30%3A04.723Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_2_1024x1024.webp?v=2024-09-20T07%3A30%3A04.723Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Наші майстер-класи дозволяють вам дослідити різноманітні техніки гончарства, від початкових основ до складних технік.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07:30:04.720Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery_Workshops_Berlin_1_1024x1024.webp?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Наша майстерня відкрита для всіх, хто бажає вивчати, експериментувати та створювати щось унікальне.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 3rem 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Чому варто вибрати ArtPottery?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Кваліфіковані інструктори:{" "}
							<br />
							<br />
							Наші інструктори — це майстри своєї справи з багаторічним досвідом, які допоможуть вам освоїти всі техніки гончарства.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Майстер-класи для всіх рівнів:{" "}
							<br />
							<br />
							Ми пропонуємо заняття для будь-якого рівня підготовки — від початкових курсів до майстерень для професіоналів.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Гнучкий графік:{" "}
							<br />
							<br />
							Наші заняття проходять у різний час протягом тижня, що дозволяє обрати зручний для вас розклад.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-12">
			<Text
				text-align="left"
				color="--darkL2"
				font="--headline2"
				margin="16px 300px 16px 0px"
				lg-margin="16px 0 16px 0px"
			>
				Наша майстерня обладнана всім необхідним для ваших творчих експериментів.{" "}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column"
				lg-width="100%"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
				sm-grid-template-rows="auto"
				sm-grid-gap="36px 0"
				margin="50px 0px 0px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/_DSC4281.jpg?v=2024-09-20T07:30:04.719Z"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
							srcSet="https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/_DSC4281.jpg?v=2024-09-20T07%3A30%3A04.719Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/_DSC4281.jpg?v=2024-09-20T07%3A30%3A04.719Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/_DSC4281.jpg?v=2024-09-20T07%3A30%3A04.719Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/_DSC4281.jpg?v=2024-09-20T07%3A30%3A04.719Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/_DSC4281.jpg?v=2024-09-20T07%3A30%3A04.719Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/_DSC4281.jpg?v=2024-09-20T07%3A30%3A04.719Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/_DSC4281.jpg?v=2024-09-20T07%3A30%3A04.719Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							Ми пропонуємо повну підтримку на всіх етапах вашого проекту — від задуму до готового виробу.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://uploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07:30:04.720Z"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
							srcSet="https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22bbe5d277001897f3ed/images/Pottery-Class-Singapore-Cover-Image-Opt-1024x683.jpg?v=2024-09-20T07%3A30%3A04.720Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text margin="8px 0px 0px 0px" color="--darkL2" font="300 18px/140% --fontFamily-sansHelvetica">
							Приєднуйтесь до ArtPottery і розкрийте свій творчий потенціал разом з нами!
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link3" />
			<Override slot="link1" />
			<Override slot="list" />
			<Override slot="link2" />
			<Override slot="link" />
		</Components.Footer12>
		<Components.Policy />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});